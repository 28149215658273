// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search Form
\* ------------------------------------------------------------------------ */

.search-form_container {
    & {
        margin: remify(0 0 20, 16);
    }
}

.search-form {
    & {
        position: relative;
    }
}

.search-form_input {
    & {
        background: $background;
        border: remify(1) solid $background_alt;
        color: $foreground;
        font-family: $body-font;
        font-size: remify(16, 16);
        margin: 0;
        outline: none;
        padding: remify(8 38 8 12, 16);
        transition: border-color 0.15s;
        width: 100%;
    }

    &:focus,
    &:hover {
        border-color: $accent;
    }
}

.search-form_button {
    & {
        background: none;
        border: 0;
        bottom: remify(1);
        color: $foreground;
        cursor: pointer;
        font-size: remify(16, 16);
        margin: 0;
        outline: none;
        padding: remify(10 12, 16);
        position: absolute;
        right: remify(1);
        top: remify(1);
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: $accent;
    }
}
