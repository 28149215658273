// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tiled Gallery without Jetpack
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-no-qualifying-type, selector-no-type, selector-class-pattern */

.gallery {
    & {
        display: block;
        width: 100%;
    }
}

.gallery-item {
    & {
        margin: remify(20, 16) 0;
        width: 100%;
    }

    img {
        margin: 0 auto;
        max-width: 100%;
        width: auto;
    }
}
