// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        background: $primary;
        box-shadow: remify(0 3 6, 16) transparentize($dark, 0.7);
        padding: remify(($content-padding * (25 / $content-padding)) ($content-padding * (25 / $content-padding)));
    }
}

.widget_link {
    & {
        color: $light;
    }

    &:focus,
    &:hover {
        color: $light_alt;
    }
}

.widget_title {
    & {
        color: $light;
        font-size: remify(16.19047619047619, 16);
        line-height: remify(20.238095238095238, 16.19047619047619);
        margin: remify(0 0 12.142857142857143, 16.19047619047619);
    }

    &.-alt {
        color: $light;
        font-size: remify(17, 16);
        line-height: remify(22.666666666666667, 17);
        text-transform: none;
    }
}

.widget_divider {
    & {
        background: $accent;
        border-radius: remify(1.5);
        height: remify(3);
    }
}
