// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation-block {
    & {
        @include layer("navigation");
    }
}

.navigation_inner {
    & {
        background: $primary;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.5) $content-padding);
    }
}

/* fullbleed variant */

.navigation-block.-fullbleed {
    & {
        background: $primary;
        padding: remify(($content-padding * 0.5) $content-padding);
    }

    > .navigation_inner {
        background: none;
        padding: 0;
    }

    > .navigation_inner.-fullbleed {
        max-width: none;
    }
}

/* flyout variant */

.navigation-block.-flyout {
    & {
        background: $primary;
        border-right: remify(1) solid darken($primary, 10);
        bottom: 999em;
        display: block;
        left: -999em;
        overflow: auto;
        position: absolute;
        right: 999em;
        top: -999em;
        transform: translateX(-100%);
        transition: bottom 0s 0.15s, left 0s 0.15s, right 0s 0.15s, top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        z-index: 999;
    }

    &.is-active {
        bottom: 0;
        left: 0;
        right: remify(80);
        top: 0;
        transform: translateX(0);
        transition: transform 0.15s;
        visibility: visible;
    }

    > .navigation_inner {
        background: none;
        max-width: none;
        padding: 0;
    }
}

/* flyout right variant */

.navigation-block.-flyout.-right {
    & {
        border-left: remify(1) solid darken($primary, 10);
        border-right: 0;
        left: 999em;
        right: -999em;
        transform: translateX(100%);
    }

    &.is-active {
        left: remify(80);
        right: 0;
    }
}

/* pulldown variant */

.navigation-block.-pulldown {
    & {
        background: $primary;
        border-right: remify(1) solid darken($primary, 10);
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: -999em;
        transform: translateY(-100%);
        transition: top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: 100%;
        z-index: 999;
    }

    &.is-active {
        top: 0;
        transform: translateY(0);
        transition: transform 0.15s;
        visibility: visible;
    }

    > .navigation_inner {
        background: none;
        max-width: none;
        padding: 0;
    }
}
