// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    /* stylelint-disable selector-no-qualifying-type, selector-no-type */

    // links

    a:not(.button) {
        color: $accent;
        text-decoration: none;
        transition: color 0.15s;
    }

    a:not(.button):hover {
        color: $accent_alt;
    }

    // titles

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $foreground;
        font-family: $heading-font;
        font-style: italic;
        font-weight: 400;
    }

    h1 {
        font-size: remify(40, 16);
        line-height: remify(50, 40);
        margin: remify(0 0 18, 40);
    }

    h2 {
        font-size: remify(28, 16);
        line-height: remify(35, 28);
        margin: remify(0 0 14, 28);
    }

    h3,
    h4 {
        font-size: remify(18, 16);
        line-height: remify(26, 18);
        margin: remify(0 0 8, 18);
    }

    h5,
    h6 {
        font-size: remify(16, 16);
        line-height: remify(24, 16);
        margin: remify(0 0 8, 16);
    }

    h2,
    h4,
    h6 {
        color: $foreground;
    }

    // text

    p,
    ol,
    ul,
    table {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(18, 16);
        font-weight: 400;
        line-height: remify(28, 18);
        margin: remify(0 0 28, 18);
    }

    ol,
    ul {
        padding-left: remify(24, 18);
    }

    .table_container {
        overflow: auto;
        width: 100%;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table td,
    table th {
        border: remify(1) solid darken($background_alt, 10);
        padding: remify(6 8, 18);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td::before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $dark_alt;
        border-color: lighten($dark_alt, 10);
        color: $light;
        font-size: remify(20, 18);
        font-weight: 400;
        padding: remify(6 8, 18);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: $background_alt;
    }

    // scrolling responisve tables

    @media screen and (max-width: $xs-break - (1 / 16)) {
        table {
            display: block;
            overflow: auto;
            padding-bottom: 1px;
        }
    }

    // blockquote

    blockquote {
        font-style: italic;
        margin: remify(0 0 18, 16);
        padding: remify(0 0 0 28, 16);
        position: relative;
    }

    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($foreground, 50);
        font-size: remify(14, 16);
    }

    blockquote > :last-child {
        margin-bottom: 0;
    }

    // horizontal rule

    hr {
        background: darken($background_alt, 10);
        border: 0;
        clear: both;
        height: remify(1);
        margin: remify(0 0 20, 16);
        width: 100%;
    }

    // images

    img {
        display: block;
        height: auto !important;
        max-width: 100%;
    }

    // alignment

    .alignleft {
        float: left;
        margin-right: remify(25, 16);
        max-width: (100% / 3);
    }

    .alignright {
        float: right;
        margin-left: remify(25, 16);
        max-width: (100% / 3);
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    /* stylelint-enable */
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .user-content {
        /* stylelint-disable selector-no-qualifying-type, selector-no-type */

        h1 {
            font-size: remify(44, 16);
        }

        h2 {
            font-size: remify(32.8, 16);
        }

        h3,
        h4 {
            font-size: remify(21.6, 16);
        }

        h5,
        h6 {
            font-size: remify(19.2, 16);
        }

        p,
        ol,
        ul,
        table {
            font-size: remify(20, 16);
        }

        blockquote {
            margin-bottom: remify(24, 16);
            padding-left: remify(36, 16);
        }

        blockquote::before {
            font-size: remify(24, 16);
        }

        blockquote p,
        blockquote ol,
        blockquote ul,
        blockquote table {
            font-size: remify(18, 16);
        }

        /* stylelint-enable */
    }
}

/* light variant */

.user-content.-light {
    /* stylelint-disable selector-no-qualifying-type, selector-no-type */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }

    /* stylelint-enable */
}
