// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }
}

.hero_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 1.25) $content-padding);
    }
}

.hero_swiper-container {
    .hero-block.-tall & .swiper-image {
        height: remify(500, 16);
    }
}

/* fullbleed variant */

.hero-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    > .hero_inner {
        background: none;
        padding: 0;
    }

    > .hero_inner.-fullbleed {
        max-width: none;
    }
}
