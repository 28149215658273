// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * (20 / $content-padding)) ($content-padding * (25 / $content-padding)));
    }
}

.content_row {
    .hero-block.-tall + .content-block > .content_inner > .content_post > &:first-child {
        margin-top: remify(($content-padding * (-37.5 / $content-padding)));
    }

    &.-padded {
        width: calc(100% + #{remify(($content-padding * (80 / $content-padding)), 16)});
    }

    &.-padded > .col,
    &.-padded > [class*="col-"] {
        padding-right: remify(($content-padding * (80 / $content-padding)), 16);
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * (20 / $content-padding)) ($content-padding * (25 / $content-padding)));
    }

    > .content_inner {
        background: none;
        padding: 0;
    }

    .hero-block.-tall + & > .content_inner {
        background: none;
        margin-top: 0;
        padding-top: 0;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}
