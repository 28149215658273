// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Overlay Closer
\* ------------------------------------------------------------------------ */

.overlay-closer {
    & {
        background: transparentize($dark, 0.25);
        border: 0;
        bottom: 999em;
        content: "\0020";
        cursor: pointer;
        display: block;
        left: -999em;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 999em;
        top: -999em;
        transition: bottom 0s 0.15s, left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: 100%;
        z-index: 998;
    }

    &.is-active {
        bottom: 0;
        left: 0;
        opacity: 1;
        right: 0;
        top: 0;
        transition: opacity 0.15s;
        visibility: visible;
    }
}
