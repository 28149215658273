// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(40, 16);
        font-style: italic;
        font-weight: 400;
        line-height: remify(50, 40);
        margin: remify(0 0 18, 40);
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .title {
        & {
            font-size: remify(44, 16);
        }
    }
}

/* alt variant */

.title.-alt {
    & {
        font-family: $body-font;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }
}
