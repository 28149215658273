// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * CTA Button
\* ------------------------------------------------------------------------ */

.cta-button {
    & {
        align-items: center;
        background-position: center center;
        background-size: cover;
        box-shadow: remify(0 2.5 5, 20) transparentize($dark, 0.7);
        color: $light;
        display: block;
        display: flex;
        font-family: $body-font;
        font-size: remify(20, 16);
        font-weight: 900;
        line-height: remify(23.333333333333333, 20);
        margin: remify(0 0 8.333333333333333, 20);
        padding: remify(16.666666666666667, 20);
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        word-spacing: 999em; // break after each word
    }

    &::before {
        background: $accent;
        background: transparentize($accent, 0.2);
        bottom: 0;
        content: "\0020";
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }
}

.cta-button_icon {
    & {
        color: $primary;
        flex: none;
        font-size: remify(53.333333333333333, 20);
        margin: remify(0 12.5 0 0, 53.333333333333333);
        position: relative;
        z-index: 1;
    }

    .icon_svg {
        bottom: 0;
    }
}

.cta-button_label {
    & {
        flex: 1 1 auto;
        position: relative;
        z-index: 1;
    }
}
