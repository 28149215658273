// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * (50 / $content-padding)) ($content-padding * (25 / $content-padding)));
    }
}

// base

.footer_row {
    .col[data-order-mobile="1"],
    [class^="col-"][data-order-mobile="1"] {
        order: 1;
    }

    .col[data-order-mobile="2"],
    [class^="col-"][data-order-mobile="2"] {
        order: 2;
    }

    .col[data-order-mobile="3"],
    [class^="col-"][data-order-mobile="3"] {
        order: 3;
    }

    .col[data-order-mobile="4"],
    [class^="col-"][data-order-mobile="4"] {
        order: 4;
    }
}

.footer_link {
    & {
        color: $light;
    }

    &:focus,
    &:hover {
        color: $light_alt;
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(14, 16);
    }
}

// module

.footer_logo {
    & {
        margin: remify(0 auto 22, 16);
        width: remify(260, 16);
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        padding: remify(($content-padding * (50 / $content-padding)) ($content-padding * (25 / $content-padding)));
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
