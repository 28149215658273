// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: $primary;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(0 ($content-padding * (20 / $content-padding)));
    }
}

// base

.header_title {
    &.-alt {
        color: $light;
        font-size: remify(18, 16);
        text-transform: none;
    }
}

// module

.header_divider {
    & {
        background: $light;
    }
}

.header_logo {
    & {
        margin: 0 auto;
        max-width: remify(300, 16);
        padding: remify(($content-padding * (20 / $content-padding)) 0);
    }
}

.header_menu-list_container {
    .menu-list.-icons {
        transform: translateX(#{remify(-10, 25)}); // space between first icon and divider end
    }
}

.header_menu-toggle {
    & {
        transform: translateX(#{remify(15, 16)}); // space between menu icon and divider end
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $primary;
        padding: remify(0 ($content-padding * (20 / $content-padding)));
    }

    > .header_inner {
        background: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}

/* overlay variant */

.header-block.-overlay {
    & {
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    & + * {
        margin-top: remify(160);
    }
}
